import React, {useCallback, useEffect, useRef, useState} from "react";
import InputTextGroup from "../../../../components/common/InputTextGroup";
import InputSelectGroup from "../../../../components/common/InputSelectGroup";
import InputTextArea from "../../../../components/common/InputTextArea";
import InputTextSearch from "../../../../components/common/InputTextSearch";
import {useSearchParams} from "react-router-dom";
import {CModal, CModalBody} from "@coreui/react";
import StoreSearch from "../../board/storeSearch";
import * as storeMethod from "../../../../api/method/store";
import {AgGridReact} from "ag-grid-react";
import InputDate from "../../../../components/common/InputDate";
import dayjs from "dayjs";
import ComboBox from "../../../../components/common/ComboBox";
import * as api from "../../../../api/api";
import {Loading} from "../../../../components/common";
import * as formatter from "../../../../utils/formatter";
import StorageRenderer from "../components/StorageRenderer";
import {DatePicker} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import {getOutSerialListByDetailSeq} from "../../../../api/api";

const StockOut = (props) => {
    const [params] = useSearchParams();
    const mode = params.get("mode");
    const companyCode = params.get("companyCode");
    const accountId = params.get("accountId");

    const printRef = useRef();
    const [stockOut, setStockOut] = useState({});
    const [stockOutSeq, setStockOutSeq] = useState(params.get("seq"));
    const [loading, setLoading] = useState(false);
    const [searchForm, setSearchForm] = useState({
        seq: -1, companyCode: companyCode,
        storeKey: "", storeName: "", productNm: "", stockDt: dayjs(), memo: "", reason: "0"
    });
    const [storageList, setStorageList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);
    const [stockList, setStockList] = useState([]);
    const [serialNo, setSerialNo] = useState("");
    const [serialList, setSerialList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [selectedStock, setSelectedStock] = useState(-1);
    const [selectedStockData, setSelectedStockData] = useState({
        serialList: []
    });
    const [storeSearchModal, setStoreSearchModal] = useState({
        open: false, data: []
    });
    const defaultClassType = {seq: "", classNm: "없음"};
    const [itemLClassList, setItemLClassList] = useState([defaultClassType]);
    const [itemMClassList, setItemMClassList] = useState([defaultClassType]);
    const [filterMClassList, setFilterMClassList] = useState([defaultClassType]);
    const [itemSClassList, setItemSClassList] = useState([defaultClassType]);
    const [filterSClassList, setFilterSClassList] = useState([defaultClassType]);

    const [selectedSerial, setSelectedSerial] = useState({})
    const [selectedOutSerial, setSelectedOutSerial] = useState({})
    const [outSerialList, setOutSerialList] = useState([])

    const defaultColDef = {
        flex: 1,
        resizable: true,
        sortable: true,
        filter: false,
        autoHeaderHeight: true
    }

    const stockGridRef = useRef();
    const [stockColumnDefs] = useState([
        {field: 'No', headerName: 'No', maxWidth: 40, valueGetter: "node.rowIndex + 1", cellClass: "static-cell"},
        {field: 'seq', headerName: 'No', maxWidth: 55, cellClass: 'normal-cell', hide: true},
        {field: 'itemLclassCd', headerName: '대분류', hide: true},
        {field: 'itemLclassNm', headerName: '대분류'},
        {field: 'itemMclassCd', headerName: '중분류', hide: true},
        {field: 'itemMclassNm', headerName: '중분류'},
        {field: 'itemSclassCd', headerName: '소분류', hide: true},
        {field: 'itemSclassCd', headerName: '소분류'},
        {field: 'productNm', headerName: '품목명'},
        {field: 'vatYn', headerName: '부가세', maxWidth: 80, cellRenderer: ComboBox,
            cellRendererParams: {
                options: [
                    {subCode: "0", codeNm: "미포함"},
                    {subCode: "1", codeNm: "포함"},
                ],
                onEventChange: (rowIndex, value) => {
                    const data = [];
                    const {api} = stockGridRef.current;
                    api.forEachNode(function (rowNode, index) {
                        data.push(rowNode.data);
                    });

                    data[rowIndex].vat = value;
                    setStockList(data);
                }
            }
        },
        {field: 'price', headerName: '출고단가', maxWidth: 80, cellClass: "number-cell", editable: true, valueFormatter: formatter.currencyFormatter},
        {field: 'qty', headerName: '수량', maxWidth: 60, cellClass: "number-cell", editable: true, valueFormatter: formatter.currencyFormatter},
        {field: "delete", headerName: "삭제", maxWidth: 60, cellClass: "static-cell", cellRenderer: e => {
                return <button className={"text-black text-lg px-1 font-bold"} onClick={() => deleteRow(e)}>✕</button>
            }
        }
    ])

    const serialGridRef = useRef();
    const [serialColumnDefs] = useState([
        {field: 'No', headerName: 'No', maxWidth: 40, valueGetter: "node.rowIndex + 1", cellClass: 'static-cell'},
        {field: 'seq', headerName: 'No', maxWidth: 55, cellClass: 'normal-cell', hide: true},
        {field: 'storageCd', headerName: '창고명', hide: true},
        {field: 'storageNm', headerName: '창고명'},
        {field: 'stockDt', headerName: '입고일', minWidth: 80, cellClass: 'static-cell'},
        {field: 'lotNo', headerName: '제품번호', minWidth: 160},
        {field: 'grade', headerName: '등급', maxWidth: 70, hide: true, cellRenderer: ComboBox,
            cellRendererParams: {
                options: [
                    {subCode: "New", codeNm: "신규"},
                    {subCode: "A", codeNm: "중고-A"},
                    {subCode: "B", codeNm: "중고-B"},
                    {subCode: "C", codeNm: "중고-C"},
                    {subCode: "D", codeNm: "중고-D"},
                ],
                onEventChange: (rowIndex, value) => {
                    const data = [];
                    const {api} = serialGridRef.current;
                    api.forEachNode(function (rowNode, index) {
                        data.push(rowNode.data);
                    });

                    data[rowIndex].grade = value;
                    setSerialList(data);
                }
            }
        }
    ])

    const outSerialGridRef = useRef()
    const [outSerialColumnDefs] = useState([
        {field: 'No', headerName: 'No', maxWidth: 40, valueGetter: "node.rowIndex + 1", cellClass: 'static-cell'},
        {field: 'seq', headerName: 'No', maxWidth: 55, cellClass: 'normal-cell', hide: true},
        {field: 'stockDt', headerName: '입고일', hide: true},
        {field: 'lotNo', headerName: '제품번호', minWidth: 100},
    ])

    const [productColumnDefs] = useState([
        {field: 'No', headerName: 'No', maxWidth: 40, valueGetter: "node.rowIndex + 1", cellClass: 'static-cell'},
        {field: 'seq', headerName: 'No', width: 40, hide: true},
        {field: 'itemLclassNm', headerName: '대분류', maxWidth: 100},
        {field: 'itemMclassNm', headerName: '중분류', maxWidth: 100},
        {field: 'itemSclassNm', headerName: '소분류', maxWidth: 100},
        {field: 'productCd', headerName: '품목코드', hide: true},
        {field: 'productNm', headerName: '품목명', width: 120},
        {field: 'price', headerName: '단가', valueFormatter: formatter.currencyFormatter, hide: true},
        {field: 'stockQty', headerName: '재고수량', maxWidth: 60, cellClass: 'number-cell', valueGetter: (e) => {
            return e.data.stockInQty - e.data.stockOutQty
        }},
        {field: 'memo', headerName: '메모'},
        {
            field: 'useProductNo', headerName: '제품번호', hide: true, valueFormatter: (e) => {
                return e.value === 'Y' ? "사용" : '제외'
            }
        },
    ])

    useEffect(() => {
        getStorageList();
        getStockProductType();
        if (stockOutSeq.length > 0) {
            search(stockOutSeq);
        }
    }, [])

    useEffect(() => {
        // 출고대상 목록이 변경되면 갱신
        selectedStockData.serialList = outSerialList
    }, [outSerialList])

    const handleSerialNo = (e) => {
        const {name, value} = e.target;
        setSerialNo(value);
    }

    const handleWhere = (e) => {
        const {name, value} = e.target;
        if (name === "itemLclassCd") {
            setSearchForm({
                ...searchForm,
                itemLclassCd: value,
                itemMclassCd: "",
                itemSclassCd: ""
            })
            setFilterMClassList([defaultClassType]);
            setFilterSClassList([defaultClassType]);

            // 중분류 필터
            const filterList = itemMClassList.filter(item => item.parentCd === value);
            setFilterMClassList((state) => state.concat(filterList));
        }
        else if (name === "itemMclassCd") {
            setSearchForm({
                ...searchForm,
                itemMclassCd: value,
                itemSclassCd: ""
            })
            // 소분류 필터
            const filterList = itemSClassList.filter(item => item.parentCd === value);
            setFilterSClassList([defaultClassType]);
            setFilterSClassList((state) => state.concat(filterList));
        }
        else {
            setSearchForm({
                ...searchForm,
                [name]: value
            })
        }
    }

    const search = (seq) => {
        setLoading(true);
        api.getStockOut(seq).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setSearchForm({
                    ...searchForm,
                    seq: data.seq,
                    storeKey: data.storeKey,
                    storeName: data.storeName,
                    stockDt: dayjs(data.stockDt), memo: data.memo, reason: data.reason
                })
                setStockOut(data);
                setStockList(data.stockOutDetailList);
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getStorageList = () => {
        setLoading(true);
        api.getStorageList(searchForm).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                const optionArr = [];
                data.map(item => {
                    const option = {
                        subCode: item.storageCd,
                        codeNm: item.storageNm
                    }
                    optionArr.push(option);
                })
                setStorageList(optionArr);
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getStockProductType = () => {
        setLoading(true);
        const params = {
            companyCode: 'VAN',
            categoryDiv: 'P'
        }
        api.getCategories(params)
            .then(result => {
                const {status, statusText, data} = result;
                if (status === 200) {
                    // 대분류
                    const lClassList = data.filter(item => item.classDiv === 'L' && item.parentCd === null);
                    setItemLClassList([defaultClassType]);
                    setItemLClassList((state) => state.concat(lClassList));

                    const mClassList = data.filter(item => item.classDiv === 'M');
                    setItemMClassList([defaultClassType]);
                    setItemMClassList((state) => state.concat(mClassList));

                    const sClassList = data.filter(item => item.classDiv === 'S');
                    setItemSClassList([defaultClassType]);
                    setItemSClassList((state) => state.concat(sClassList));
                }
                else {
                    console.error(statusText);
                }
            })
            .catch((e) => {
                console.error(e.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getStoreWhere = () => {
        const value = searchForm.storeName;
        if (value === undefined || value.length === 0) {
            alert("검색 정보를 입력해주세요.");
            return;
        }

        const params = {
            companyCode: companyCode,
            storeSyn: value
        }
        storeMethod.getStoreListWhere(params).then((result) => {
            setLoading(false);
            setStoreSearchModal({
                open: true,
                data: result
            })
        })
    }

    const storeSelectCallback = (e) => {
        setStoreSearchModal({
            open: false, data: []
        })

        setSearchForm((state) => ({
            ...state,
            storeKey: e.storeKey,
            storeName: e.storeName
        }))
    }

    const getProductList = () => {
        const params = {
            companyCode: companyCode,
            itemLclassCd: searchForm.itemLclassCd,
            itemMclassCd: searchForm.itemMclassCd,
            itemSclassCd: searchForm.itemSclassCd,
            productNm: searchForm.productNm
        }
        setLoading(true);
        api.getStockProduct(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                if (data.length > 0) {
                    // 재고가 있는거만 필터링
                    const filterArray = [];
                    data.map(item => {
                        if ((item.stockInQty - item.stockOutQty) > 0) {
                            filterArray.push(item)
                        }
                    })
                    setProductList(filterArray)
                }
                else {
                    setProductList([])
                }
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getAvailableProductSerialList = (obj) => {
        const params = {
            productCd: obj.productCd
        }

        setLoading(true);
        api.getProductSerialListAvailable(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                // 데이터를 가져오되 출고대상 제품에 있는 목록은 제외한다
                // setSerialList(data.filter(aItem => !outSerialList.some(bItem => aItem.lotNo === bItem.lotNo)))
                setSerialList(data)

                // detail 정보가 있을 경우 이전 출고데이터도 가져온다
                if (mode === 'R') {
                    api.getOutSerialListByDetailSeq(obj.seq).then(result => {
                        const {data, status, statusText} = result
                        if (status === 200) {
                            setOutSerialList(data)
                        }
                    })
                }
                else {
                    setOutSerialList(obj.serialList)
                }
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const addRow = () => {
        const stock = {
            seq: "",
            companyCode: 'VAN',
            productType: '0',
            productCd: "",
            productNm: "",
            vatYn: '0',
            price: 0,
            qty: 1,
            serialList: []
        }
        setStockList((state) => {
            return [...state, stock];
        })
    }

    const deleteRow = (e) => {
        const data = [];
        const {api} = stockGridRef.current;
        api.forEachNode(function (rowNode, index) {
            data.push(rowNode.data);
        });
        data.splice(e.rowIndex, 1)
        setStockList(data);

        // 삭제 후에는 선택 인덱스 초기화
        setSelectedStock(-1)
    }

    const addSerial = () => {
        if (selectedStock < 0) {
            window.alert("출고 대상을 선택하세요.");
            return;
        }

        if (serialNo.length === 0) {
            window.alert("제품번호를 입력하세요.");
            return;
        }

        const idx = serialList.findIndex(item => item.lotNo === serialNo);
        if (idx > -1) {
            window.alert("이미 등록되어 있는 제품번호입니다.")
            return;
        }

        const serial = {
            seq: "",
            storageCd: "0",
            lotNo: serialNo,
            stockInDt: dayjs().format("YYYYMMDD"),
            grade: "A"
        }

        const copiedItems = [...stockList];
        let tempSerialList = copiedItems[selectedStock].serialList;
        tempSerialList = [...serialList, serial];

        copiedItems[selectedStock].serialList = tempSerialList;
        setStockList(copiedItems);

        setSerialList((state) => {
            return [...state, serial];
        })

        setSerialNo("");
    }

    const deleteSerialRow = (e) => {
        const data = [];
        const {api} = serialGridRef.current;
        api.forEachNode(function (rowNode, index) {
            data.push(rowNode.data);
        });
        data.splice(e.rowIndex, 1)
        setSerialList(data);
    }

    const onSave = () => {
        if (window.confirm("출고 정보를 저장하시겠습니까?")) {
            const {seq, stockDt, storeKey, reason, memo} = searchForm;
            if (storeKey.length === 0) {
                window.alert("출고처를 먼저 선택해주세요.");
                return;
            }

            const stockOut = {
                seq: seq,
                companyCode: companyCode,
                stockDt: dayjs(stockDt).format("YYYYMMDD"),
                storeKey: storeKey,
                purchaseAmt: 0,
                vatAmt: 0,
                totalAmt: 0,
                reason: reason,
                memo: memo,
                stockOutDetailList: [],
                instUser: accountId,
                updUser: accountId
            }

            let stockQty = 0;
            let vatAmt = 0;
            let purchaseAmt = 0;

            const data = [];
            stockGridRef.current.api.forEachNode(function (rowNode, index) {
                let vat = 0;
                const purchase = rowNode.data.price * rowNode.data.qty;
                const qty = rowNode.data.qty;
                if (rowNode.data.vatYn === "1") {
                    vat = Math.round(purchase - (purchase / 1.1));
                }
                purchaseAmt += purchase;
                vatAmt += vat;
                stockQty += qty;

                data.push(rowNode.data);
            });

            stockOut.stockOutDetailList = data;
            stockOut.purchaseAmt = purchaseAmt;
            stockOut.vatAmt = vatAmt;
            stockOut.totalAmt = purchaseAmt + vatAmt;
            stockOut.stockQty = stockQty;

            putStockOut(stockOut);
        }
    }

    const putStockOut = (stockOut) => {
        setLoading(true);
        api.putStockOut(stockOut).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                window.alert("정상적으로 처리되었습니다.");
                setSearchForm({
                    seq: data.seq,
                    ...searchForm,
                })
                search(data.seq);
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onRowSelected = (e) => {
        if (!e.node.selected) return;
        setSelectedStock(e.node.rowIndex);
        setSelectedStockData(e.node.data);

        getAvailableProductSerialList(e.node.data);
    }

    const onRowDoubleClicked = (e) => {
        let stock = {};
        if (selectedStock > -1 && stockList[selectedStock] !== undefined) {
            // 선택한 재고상품 업데이트
            const copiedItems = [...stockList];
            copiedItems[selectedStock].itemLclassCd = e.data.itemLclassCd;
            copiedItems[selectedStock].itemLclassNm = e.data.itemLclassNm;
            copiedItems[selectedStock].itemMclassCd = e.data.itemMclassCd;
            copiedItems[selectedStock].itemMclassNm = e.data.itemMclassNm;
            copiedItems[selectedStock].itemSclassCd = e.data.itemSclassCd;
            copiedItems[selectedStock].itemSclassNm = e.data.itemSclassNm;
            copiedItems[selectedStock].productType = e.data.productType;
            copiedItems[selectedStock].productCd = e.data.productCd;
            copiedItems[selectedStock].productNm = e.data.productNm;
            copiedItems[selectedStock].price = e.data.price;
            setStockList(copiedItems);
        }
        else {
            stock.seq = "";
            stock.companyCode = 'VAN';
            stock.itemLclassCd = e.data.itemLclassCd;
            stock.itemLclassNm = e.data.itemLclassNm;
            stock.itemMclassCd = e.data.itemMclassCd;
            stock.itemMclassNm = e.data.itemMclassNm;
            stock.itemSclassCd = e.data.itemSclassCd;
            stock.itemSclassNm = e.data.itemSclassNm;
            stock.productType = e.data.productType;
            stock.productCd = e.data.productCd;
            stock.productNm = e.data.productNm;
            stock.vatYn = '0';
            stock.price = e.data.price;
            stock.qty = 1;
            stock.serialList = [];

            setStockList((state) => {
                return [...state, stock];
            })
        }
        setSelectedStock(-1);

        // 선택한 제품의 시리얼목록 가져오기
        getAvailableProductSerialList(e.data);
    }

    const onSerialListRowSelected = (e) => {
        if (!e.node.selected) return;
        setSelectedSerial(e.node.data)
    }

    const onOutSerialListRowSelected = (e) => {
        if (!e.node.selected) return;
        setSelectedOutSerial(e.node.data)
    }

    const onLeft = () => {
        // 조회시에는 변경이 불가하다
        if (mode === 'R') return

        if (selectedOutSerial === undefined || Object.keys(selectedOutSerial).length === 0) return

        // 출고 리스트에서 삭제
        const newList = outSerialList.filter(item => item.lotNo !== selectedOutSerial.lotNo)
        setOutSerialList(newList)

        // 제품 리스트에 추가
        setSerialList((prev) => ([
            ...prev, selectedOutSerial
        ]))

        // 처리가 끝났으면 초기화
        setSelectedSerial({})
        setSelectedOutSerial({})
    }

    const onRight = () => {
        // 조회시에는 변경이 불가하다
        if (mode === 'R') return

        // 선택된 값이 있는지 확인
        if (selectedSerial === undefined || Object.keys(selectedSerial).length === 0) return

        // 리스트에서 출고 대상으로
        const outSerial = {
            ...selectedSerial,
            seq: -1,
            stockDt: dayjs().format("YYYYMMDD"),
            instUser: accountId
        }
        // 제품 리스트에서 삭제
        const newList = serialList.filter(item => item.lotNo !== selectedSerial.lotNo)
        setSerialList(newList)

        // 출고 리스트에 추가
        setOutSerialList((prev) => ([
            ...prev, outSerial
        ]))

        // 처리가 끝났으면 초기화
        setSelectedSerial({})
        setSelectedOutSerial({})
    }

    return (
        <div className={"flex flex-col"}>
            <div className={"flex flex-row gap-2 m-2"}>
                {loading ? <Loading/> : null}
                <div className={"flex flex-col bg-white border border-gray-800 p-2 shadow"}>
                    <div className={"flex flex-col flex-1 border bg-gray-100"}>
                        <div className={"flex flex-row gap-1"}>
                            <div className={"flex flex-row p-2 gap-2 items-center"}>
                                <span className={"w-20 text-sm font-bold text-nowrap"}>출고일자</span>
                                <DatePicker className={"h-6 px-2 text-sm"}
                                            placeholder={""}
                                            locale={locale}
                                            value={searchForm.stockDt}
                                            onChange={(dayjs, dateString) => {
                                                setSearchForm((state) => ({
                                                    ...state, stockDt: dayjs
                                                }));
                                            }}/>
                            </div>
                            {/*<InputDate name={"stockDt"} title={"출고일자"} value={searchForm.stockDt} setValue={handleWhere} />*/}
                            <InputTextSearch name={"storeName"} title={"출고처"}
                                             disabled={mode === "R"}
                                             value={searchForm.storeName} search={getStoreWhere} setValue={handleWhere} />
                            <InputSelectGroup name={"reason"} title={"출고사유"} options={[
                                {subCode: "0", codeNm: "가맹점 AS교체"},
                                {subCode: "1", codeNm: "가맹점 기기변경"},
                                {subCode: "2", codeNm: "가맹점 단말기교체"},
                                {subCode: "3", codeNm: "가맹점 무상교체"},
                                {subCode: "4", codeNm: "가맹점 무상임대"},
                                {subCode: "5", codeNm: "가맹점 유상임대"},
                                {subCode: "6", codeNm: "가맹점 추가임대"},
                                {subCode: "7", codeNm: "가맹점 추가판매"},
                                {subCode: "8", codeNm: "가맹점판매"},
                                {subCode: "9", codeNm: "무상포스용지출고"},
                                {subCode: "10", codeNm: "삼성할부 판매"},
                                {subCode: "11", codeNm: "제조사 AS출고"},
                                {subCode: "12", codeNm: "밴사변경"},
                                {subCode: "13", codeNm: "폐기"},
                                {subCode: "14", codeNm: "기타"},
                            ]} setValue={handleWhere} value={searchForm.reason} />
                        </div>
                        <InputTextArea name={"memo"} title={"메모"} value={searchForm.memo} setValue={handleWhere} />
                    </div>
                    <div className="flex justify-content-between mt-1">
                        <div className={"flex flex-row gap-1"}>
                            {/*<button className={"bg-red-500 px-3 rounded text-white text-sm"} onClick={addRow}>추가</button>*/}
                        </div>
                        <div className={"d-grid gap-1 d-md-flex"}>
                            <button className={"bg-blue-950 px-3 rounded text-white text-sm"} onClick={onSave}>저장</button>
                        </div>
                    </div>
                    <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 250}}>
                        <AgGridReact
                            ref={stockGridRef}
                            rowSelection={'single'}
                            rowHeight={32}
                            headerHeight={32}
                            rowData={stockList}
                            onRowSelected={onRowSelected}
                            defaultColDef={defaultColDef}
                            columnDefs={stockColumnDefs}>
                        </AgGridReact>
                    </div>
                    <div className={"mt-1"}>
                        <div className={"flex flex-row gap-2"}>
                            <div className={"flex-1 flex flex-col"}>
                                <span className={"text-sm font-bold"}>제품번호</span>
                                <div className="ag-theme-alpine mt-1 flex-1 w-[100%] h-[250px]">
                                    <AgGridReact
                                        ref={serialGridRef}
                                        rowSelection={'single'}
                                        rowHeight={32}
                                        headerHeight={32}
                                        rowData={serialList}
                                        onRowSelected={onSerialListRowSelected}
                                        defaultColDef={defaultColDef}
                                        columnDefs={serialColumnDefs}>
                                    </AgGridReact>
                                </div>
                            </div>
                            <div className={"flex flex-col gap-2 items-center justify-center"}>
                                <button className={"w-8 h-8 rounded bg-gray-300 font-bold"} onClick={onRight}>{"▶︎"}</button>
                                <button className={"w-8 h-8 rounded bg-gray-300 font-bold"} onClick={onLeft}>{"◀︎"}</button>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm font-bold"}>출고제품</span>
                                <div className="ag-theme-alpine mt-1 w-[250px] h-[250px]">
                                    <AgGridReact
                                        ref={outSerialGridRef}
                                        rowSelection={'single'}
                                        rowHeight={32}
                                        headerHeight={32}
                                        rowData={outSerialList}
                                        onRowSelected={onOutSerialListRowSelected}
                                        defaultColDef={defaultColDef}
                                        columnDefs={outSerialColumnDefs}>
                                    </AgGridReact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"flex flex-col flex-1 p-2 border border-gray-800 bg-white shadow"}>
                    <div className={"mt-1"}>
                        <div className="flex justify-content-between">
                            <div className={"flex flex-col"}>
                                <div className={"flex flex-row"}>
                                    <InputSelectGroup name={"itemLclassCd"}
                                                      title={"대분류"}
                                                      optionType={"C"}
                                                      value={searchForm.itemLclassCd}
                                                      options={itemLClassList}
                                                      setValue={handleWhere} />
                                    <InputSelectGroup name={"itemMclassCd"}
                                                      title={"중분류"}
                                                      optionType={"C"}
                                                      value={searchForm.itemMclassCd}
                                                      options={filterMClassList}
                                                      setValue={handleWhere} />
                                    <InputSelectGroup name={"itemSclassCd"}
                                                      title={"소분류"}
                                                      optionType={"C"}
                                                      value={searchForm.itemSclassCd}
                                                      options={filterSClassList}
                                                      setValue={handleWhere} />
                                </div>
                                <InputTextSearch name={"productNm"} title={"상품명"} search={getProductList} setValue={handleWhere} />
                            </div>
                        </div>
                        <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 550}}>
                            <AgGridReact
                                rowSelection={'single'}
                                rowHeight={32}
                                headerHeight={32}
                                onRowDoubleClicked={onRowDoubleClicked}
                                rowData={productList}
                                defaultColDef={defaultColDef}
                                columnDefs={productColumnDefs}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>

                <CModal alignment="center"
                        visible={storeSearchModal.open}
                        onClose={() => setStoreSearchModal({
                            open: false, data: []
                        })}>
                    <CModalBody>
                        <StoreSearch data={storeSearchModal.data} callback={storeSelectCallback}/>
                    </CModalBody>
                </CModal>
            </div>

            {/*<div id={"grid_product"} className={"p-5 ag-theme-alpine visible-print"} style={{width: "100%"}}>*/}
            {/*    <h1 className={"text-3xl font-bold"}><center>거 래 명 세 서</center></h1>*/}
            {/*    <br />*/}
            {/*    <div className={"flex flex-row justify-between"}>*/}
            {/*        <span className={"text-xl"}>123123 귀 하</span>*/}
            {/*        <span className={"text-xl"}>인쇄일자 : {dayjs().format("YYYY-MM-DD")}</span>*/}
            {/*    </div>*/}
            {/*    <table className={"w-full border-2 border-black mb-2"}>*/}
            {/*        <tr>*/}
            {/*            <td className={"p-2 w-[50%] border-2 border-black text-center"} colSpan={2}>공 급 받 는 자</td>*/}
            {/*            <td className={"p-2 w-[50%] border-2 border-black text-center"} colSpan={2}>공 급 자</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td className={"p-2 w-[120px] border-2 border-black text-center"}>상호</td>*/}
            {/*            <td className={"p-2 flex-1 border-2 border-black"}>&nbsp;</td>*/}
            {/*            <td className={"p-2 w-[120px] border-2 border-black text-center"}>상호</td>*/}
            {/*            <td className={"p-2 flex-1 border-2 border-black"}>테스트</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td className={"p-2 w-20 border-2 border-black text-center"}>주소</td>*/}
            {/*            <td className={"p-2 flex-1 border-2 border-black"}>&nbsp;</td>*/}
            {/*            <td className={"p-2 w-20 border-2 border-black text-center"}>주소</td>*/}
            {/*            <td className={"p-2 flex-1 border-2 border-black"}>테스트</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td className={"p-2 w-20 border-2 border-black text-center"}>대표/전화</td>*/}
            {/*            <td className={"p-2 flex-1 border-2 border-black"}>&nbsp;</td>*/}
            {/*            <td className={"p-2 w-20 border-2 border-black text-center"}>대표/전화</td>*/}
            {/*            <td className={"p-2 flex-1 border-2 border-black"}>테스트</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td className={"p-2 w-20 border-2 border-black text-center"}>사업자번호</td>*/}
            {/*            <td className={"p-2 flex-1 border-2 border-black"}>&nbsp;</td>*/}
            {/*            <td className={"p-2 w-20 border-2 border-black text-center"}>사업자번호</td>*/}
            {/*            <td className={"p-2 flex-1 border-2 border-black"}>테스트</td>*/}
            {/*        </tr>*/}
            {/*    </table>*/}
            {/*    <div className={"flex flex-row"}>*/}
            {/*        <div className={"w-[50%] p-2 border-2 border-black text-center"}>*/}
            {/*            총 합계금액 : {0}원 정*/}
            {/*        </div>*/}
            {/*        <div className={"w-[50%] p-2 border-2 border-black text-center"}>*/}
            {/*            총 합계금액 : {0}원 정*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <br />*/}
            {/*    <AgGridReact*/}
            {/*        ref={printRef}*/}
            {/*        animateRows={true}*/}
            {/*        rowData={stockList}*/}
            {/*        defaultColDef={defaultColDef}*/}
            {/*        columnDefs={stockColumnDefs}>*/}
            {/*    </AgGridReact>*/}
            {/*</div>*/}
        </div>
    )
}

export default StockOut;